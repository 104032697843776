import { AppSidebar } from "@/components/app-sidebar";
import { useState } from "react";
import { Button } from "../ui/button";
import { Separator } from "@/components/ui/separator";
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "@/components/ui/sidebar";
import {
 
  Map,
  ScanLine,
 
} from "lucide-react";
import {
  Calendar,
 
  UsersRound,
  TicketPercent,
  Send,
  
  MessageSquare,
  Headset,
  
  Zap,
 
  Smile,
  Instagram,

  MapPinned,

  ChartBar,
 
  Users,
  Bot

} from "lucide-react";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import EventPage from "@/pages/EventPage";
import AutoCommentorPage from "@/pages/AutoCommentorPage";
import Coupon from "../coupons/coupon";
import BookingsPage from "@/pages/BookingsPage";
import TransactionsPage from "@/pages/TransactionsPage";
import MissedCheckout from "../leads/missed-checkout";
import { SettlementInfo } from "@/components/settlement-info";
import StayInfo from "@/components/stay-info";
import { ReviewsTable } from "@/components/review";
import { TeamsTable } from "@/components/team";
// import{ShopTable} from "@/components/shop"
import { ProductsTable } from "@/components/shop";
import WhatsappQ from "@/components/whatsapp";
import PickupPointsTable from "@/components/pickup-point";
import { ModeToggle } from "./ModeToggle";
import Cards from "../cards";
import { DataTableDemo } from "../opreations";
import { TableDemo } from "../members/members";
import Membership from "../members/membership-plan";
import AgentsTab from "../agent";
import LeadPage from "@/pages/LeadPage";
import { useAuthStore } from "@/store/store";
import { useEffect } from "react";
import CustomersPage from "@/pages/CustomersPage";
import { useCompanyStore } from "@/store/companyStore";
import { useUserStore } from "@/store/userStore";
import RefundsPage from "../refunds-page";
import AnalyticsPage from "@/pages/AnalyticsPage";
import setupAxios from "@/setup/axiosSetup";
import axios from "axios";
import { Helmet } from "react-helmet";
import { CirclePlus } from "lucide-react";
import AIChatbotPage from "@/pages/AiChatbot";
import Checkout from "../checkout/checkout";

const SwitchVarHandler = (switchVar) => {
  switch (switchVar.get("switchVar")) {
    case "analytics":
      return <AnalyticsPage />;
    case "refunds":
      return <RefundsPage />;
    case "customers":
      return <CustomersPage />;
    case "enquiry":
      return <LeadPage />;
    case "instacomment":
      return <AutoCommentorPage />;
    case "ai-chatbot":
      return <AIChatbotPage />;
    case "allplans":
      return <EventPage />;
    case "companyCoupon":
      return <Coupon />;
    case "allbookings":
      return <BookingsPage />;
    case "alltransactions":
      return <TransactionsPage />;
    case "missed-checkouts":
      return <MissedCheckout />;
    case "settlement":
      return <SettlementInfo />;
    case "stay":
      return <StayInfo />;
    case "pickuppoints":
      return <PickupPointsTable />;
    case "review":
      return <ReviewsTable />;
    case "shop":
      return <ProductsTable />;
    case "whatsapp":
      return <WhatsappQ />;
    case "team":
      return <TeamsTable />;
    // case "allbookings":
    //   return <Cards />;
    case "operationTab":
      return <DataTableDemo />;
    case "members":
      return <TableDemo />;
    case "agents":
      return <AgentsTab />;
    case "membershipPlan":
      return <Membership />;
    case 'checkout':
      return <Checkout />

    default:
      return <EventPage />;
  }
};



export default function Layout({ children }) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const { setCompanySlug } = useAuthStore();
  const {
    companyData,
    fetchTeamMembers,
    fetchEvents,
    fetchCategories,
    fetchCompanyData,
    isLoading,
    isError,
  } = useCompanyStore((state) => state);
  const { fetchUserDetails, fetchTabsDetails } = useUserStore();
  const { slug } = useParams();
  const { accessToken, companyslug } = useAuthStore();
  const [switchVar] = useSearchParams();
  const [isSlugSet, setIsSlugSet] = useState(false);

  const [data, setData] = useState({
    "user": {
      "name": "Logout World",
      "email": "m@example.com",
      "avatar": "/avatars/shadcn.jpg"
    },
    "teams": [
      {
        "name": "LOGOUT",
        "logo": "Compass"
      }
    ],
    "navMain": [
      {
        "title": "My Events",
        "apiKey": "my_events",
        "url": "allplans",
        "icon": Map,
        "requiredAccess": ["ACCESS.full", "ACCESS.finance", "ACCESS.operation", "ACCESS.sales"]
      },
      {
        "title": "Leads",
        "apiKey": "leads",
        "url": "#",
        "icon": Headset,
        "requiredAccess": ["ACCESS.sales", "ACCESS.full"],
        "items": [
          {
            "title": "Missed Checkouts",
            "apiKey": "missed_checkout",
            "url": "missed-checkouts"
          },
          {
            "title": "Channel Leads",
            "apiKey": "channel_leads",
            "url": "enquiry"
          }
        ]
      },
      {
        "title": "Bookings",
        "apiKey": "bookings",
        "url": "",
        "icon": Zap,
        "requiredAccess": ["ACCESS.finance", "ACCESS.operation"],
        "items": [
          {
            "title": "All Bookings",
            "apiKey": "all_bookings",
            "url": "allbookings"
          },
          {
            "title": "Transactions",
            "apiKey": "transactions",
            "url": "alltransactions"
          },
          {
            "title": "Settlements",
            "apiKey": "settlements",
            "url": "settlement"
          },
          {
            "title": "Customers",
            "apiKey": "customers",
            "url": "customers"
          },
          {
            "title": "Refunds",
            "apiKey": "customers",
            "url": "refunds"
          }
        ]
      },
      {
        "title": "Calendar",
        "apiKey": "calendar",
        "url": `/calendar/${companyslug}`,
        "icon": Calendar
      },
      {
        "title": "Coupons",
        "apiKey": "coupons",
        "url": "companyCoupon",
        "icon": TicketPercent
      },
      {
        "title": "Oneinbox",
        "apiKey": "oneinbox",
        "url": `/app/auth/${accessToken}`,
        "icon": MessageSquare
      },
      {
        "title": "Operations",
        "apiKey": "operations",
        "url": `/dashboard/${companyslug}/operation-summary-crm/`,
        "icon": UsersRound,
        "requiredAccess": ["ACCESS.operation"]
      },
      {
        "title": "Membership & Agents",
        "apiKey": "membership_agents",
        "url": "#",
        "icon": Smile,
        "requiredAccess": ["ACCESS.full"],
        "items": [
          {
            "title": "Membership Plans",
            "apiKey": "membership_plans",
            "url": "membershipPlan"
          },
          {
            "title": "Members",
            "apiKey": "members",
            "url": "members"
          },
          {
            "title": "Agent",
            "apiKey": "agent",
            "url": "agents"
          }
        ]
      },
      {
        "title": "Airlink",
        "apiKey": "airlink",
        "url": `https://myair.link/auth/${accessToken}/${companyslug}`,
        "icon": Send
      },
      {
        "title": "Instagram",
        "apiKey": "instagram",
        "url": "#",
        "icon": Instagram,
        "requiredAccess": ["ACCESS.operation", "ACCESS.sales_admin"],
        "items": [
          {
            "title": "DM Bot Status",
            "apiKey": "dm_bot_status",
            "url": `/dashboard/${companyslug}/update-bot-status/`
          },
          {
            "title": "Bot Q & A",
            "apiKey": "bot_qna",
            "url": `/dashboard/${companyslug}/list-bot-question-answer/`
          },
          {
            "title": "Custom Bot Replies",
            "apiKey": "custom_bot_replies",
            "url": `/dashboard/${companyslug}/update-bot-reply/`
          },
          {
            "title": "Auto Commentor",
            "apiKey": "auto_commentor",
            "url": "instacomment"
          },
          {
            "title": "AI Prompts",
            "apiKey": "ai_prompts",
            "url": `/dashboard/${companyslug}/company-prompts/`
          },
          {
            "title": "Chatbot",
            "apiKey": "ai-chatbot",
            "url": "ai-chatbot",
            "icon": Bot
          },
          // {
          //   "title": "Chatbot",
          //   "apiKey": "ai-chatbot",
          //   "url": "ai-chatbot"
          // }
        ]
      },
      {
        "title": "WhatsApp",
        "apiKey": "whatsapp",
        "url": "#",
        "icon": FaWhatsapp,
        "requiredAccess": ["ACCESS.operation", "ACCESS.sales_admin"],
        "items": [
          {
            "title": "WhatsApp Q & A",
            "apiKey": "whatsapp_qna",
            "url": `/dashboard/${companyslug}/list-whatsapp-question-answer/`
          }
        ]
      },
      {
        "title": "Pickup Points",
        "apiKey": "pickup_points",
        "url": "pickuppoints",
        "icon": MapPinned
      },
      {
        "title": "Analytics",
        "apiKey": "analytics",
        "url": "analytics",
        "icon": ChartBar,
        "requiredAccess": ["ACCESS.finance", "ACCESS.sales_admin"]
      },
      {
        "title": "Manage Team",
        "apiKey": "manage_team",
        "url": "team",
        "icon": Users,
        "requiredAccess": ["ACCESS.full"]
      }
    ]
  })

    const {userData, tabs} = useUserStore();

  const filterNavItems = (navItems, tabs) => {
    return navItems.filter((item) => {

      if(tabs?.is_superuser){
        return true
      }
      // Check if the item has an apiKey and if it's present in the tabs array, or default to true if no apiKey
      const hasTabAccess = item.apiKey ? tabs?.tabs?.includes(item.apiKey) : true;
  
      // If item has sub-items, filter those as well
      if (item.items) {
        item.items = item.items.filter((subItem) => 
          subItem.apiKey ? tabs?.tabs?.includes(subItem.apiKey) : true
        );
        console.log("subItem",item, item.items)
      }

      if(item.apiKey == 'leads'){
        if(item.items.length == 0){
          return false
        }
      }

      if(item.apiKey == 'bookings'){
        if(item.items.length == 0){
          return false
        }
      }

      if(item.apiKey == 'membership_agents'){
        if(item.items.length == 0){
          return false
        }
      }

      if(item.apiKey == 'whatsapp'){
        if(item.items.length == 0){
          return false
        }
      }

      if(item.apiKey == 'instagram'){
        if(item.items.length == 0){
          return false
        }
      }
  
      return hasTabAccess;
    });
  };

  const accessibleNavItems = filterNavItems(data.navMain, tabs);


  useEffect(() => {
    setupAxios(axios);
    async function init() {
      await fetchTabsDetails(slug);
      if(slug == 'trove-experiences') {
        const updated = [...data.navMain]
        updated.splice(8, 0, {
          "title": "Checkout",
          "apiKey": "checkout",
          "url": "checkout",
          "icon": ScanLine
        })
        setData((prev) => ({
          ...prev,
          navMain: updated
        }))     
    }
      await fetchEvents();
      await fetchCompanyData();
      await fetchUserDetails();
      await fetchCategories();
      await fetchTeamMembers();
  }
  init();
  }, [slug]);

  

  useEffect(() => {
    setCompanySlug(slug);
    setIsSlugSet(true);
  }, [slug]);

  

  // if (access_token) {
    
  //   setUser(access_token);
  //   window.location.href = `${window.origin}/dashboard/${slug}/`;
  // }

  const handleCreateEvent = () => {
    if (companyslug == "workshop-hive" || companyslug == "pouls-of-art" || companyslug == "chalkbooard" || companyslug == "kohli-enterprises" || companyslug == "nomofomo" || companyslug == "starscapes" || companyslug == "trove-experiences"){
      window.open(`${import.meta.env.VITE_APP_API_URL}/create-event/${companyslug}/`)
    }
    else{
      window.open(`${import.meta.env.VITE_APP_API_URL}/tours/hosts/${companyslug}/create-new-event`)
    }
  }

  const companyDetails = companyData.data;


  if(!isSlugSet){
    return null
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${companyDetails.name} | Dashboard V3`}</title>
        <link rel="icon" href={companyDetails.cover_pic} />
      </Helmet>
      <SidebarProvider>
        <AppSidebar teams={data.teams} navItems={accessibleNavItems}/>
        <SidebarInset>
          <header className="flex border-b-2 h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
            <div className="flex justify-between w-full items-center gap-2 px-4">
              <SidebarTrigger className="-ml-1" />
              <Separator orientation="vertical" className="mr-2 h-4" />
              <div className="flex items-center mr-2 gap-2">
                <div>
                  <Button onClick={handleCreateEvent}>
                    <CirclePlus size={16} />
                    Create Event
                  </Button>
                </div>
                <div className="h-10 mx-2 border-[1px]"></div>
                <div className="w-8 h-8 relative bg-white overflow-hidden rounded-full">
                  <img
                    src={companyDetails.cover_pic || companyDetails}
                    alt="logo"
                    className="w-full h-full object-cover absolute"
                  />
                </div>
                <span className="text-base hidden md:flex font-bold">
                  {companyDetails.name}
                </span>
              </div>
              {/* <ModeToggle /> */}
              {/* <Breadcrumb>
                <BreadcrumbList>
                  <BreadcrumbItem className="hidden md:block">
                    <BreadcrumbLink href="#">
                      Building Your Application
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator className="hidden md:block" />
                  <BreadcrumbItem>
                    <BreadcrumbPage>Data Fetching</BreadcrumbPage>
                  </BreadcrumbItem>
                </BreadcrumbList>
              </Breadcrumb> */}
          </div>
        </header>
        <div className="px-5 pt-5">{SwitchVarHandler(switchVar)}</div>
      </SidebarInset>
    </SidebarProvider>
  </>
  );
}
